import { useEffect } from 'react';
import { GetStaticPropsContext } from 'next';
import { useLocales, setupLocale } from '@core/i18n';
import { useAnalytics } from '@core/analytics';
import { PublicGuard } from '@core/ui/guard';
import Login from 'src/features/login';
import MetaHead from '@core/meta-head';

export default function SignInPage() {
  const analytics = useAnalytics();
  const { translate } = useLocales();

  useEffect(() => {
    analytics.trackPage('SIGN_IN');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MetaHead
        removeBowldPrefix
        title={`${translate('features.login.metaTitle')}`}
      />

      <PublicGuard>
        <Login />
      </PublicGuard>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: await setupLocale(locale),
    },
  };
}
