import { useState } from 'react';
import { useRouter } from 'next/router';
import { Stack, Typography, Button } from '@mui/material';
import { useLocales } from '@core/i18n';
import Form from './form';
import Logo from '@core/ui/components/logo/Logo';

interface Props {
  onShowForm: (show: boolean) => void;
}

export default function Main({ onShowForm }: Props) {
  const { translate } = useLocales();
  const { query } = useRouter();

  const [successEmail, setSuccessEmail] = useState('');

  return (
    <Stack
      spacing={5}
      sx={{
        width: 1,
        height: 1,
        px: 2,
        pt: { sm: 8, md: 12 },
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Logo />

      <Stack justifyContent={'center'} alignItems="center">
        <Typography variant="h3">
          {successEmail
            ? translate('features.login.success.title')
            : query.w_name
            ? translate('features.login.titleJoin', {
                workspace: query.w_name,
              })
            : translate('features.login.title')}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        >
          {successEmail
            ? translate('features.login.success.subtitle', {
                email: successEmail,
              })
            : translate('features.login.subtitle')}
        </Typography>
      </Stack>

      {successEmail ? (
        <Button
          onClick={() => {
            setSuccessEmail('');
            onShowForm(true);
          }}
        >
          {translate('features.login.success.cta')}
        </Button>
      ) : (
        <Form
          onFinish={(email) => {
            setSuccessEmail(email);
            onShowForm(false);
          }}
        />
      )}
    </Stack>
  );
}
