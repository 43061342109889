import { Stack, Button, ButtonProps, Divider, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useLocales } from '@core/i18n';
import FormProvider, { RHFTextField } from '@core/ui/components/hook-form';
import Image from '@core/ui/components/image/Image';
import { useAuth } from '@core/auth';
import { ROUTES } from '@core/config';
import { sendAuthEmail } from '@core/services/users';

function GoogleButton(props: ButtonProps) {
  const { translate } = useLocales();

  return (
    <Button
      sx={{
        bgcolor: 'white',
        border: '1px solid',
        borderColor: 'divider',
        color: 'text.primary',
        ':hover': {
          bgcolor: 'grey.200',
        },
      }}
      startIcon={
        <Image
          src="/assets/icons/ic_google.svg"
          sx={{
            width: '16px',
          }}
        />
      }
      {...props}
    >
      {translate('features.login.google.cta')}
    </Button>
  );
}

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

interface Props {
  onFinish: (email: string) => void;
}

export default function Form({ onFinish }: Props) {
  const { translate } = useLocales();
  const { signInWithGoogle } = useAuth();
  const { push, query } = useRouter();

  const FormSchema = Yup.object().shape({
    email: Yup.string()
      .required(translate('features.login.email.invalidMessage'))
      .email(translate('features.login.email.invalidMessage')),
  });

  const defaultValues = {
    email: query.email as string,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await sendAuthEmail(data.email);
      onFinish(data.email);
    } catch (error: any) {
      setError('afterSubmit', {
        ...error,
        message: translate('common.customErrors.unknownError'),
      });
    }
  };

  return (
    <Stack spacing={3} sx={{ width: 1, maxWidth: '320px' }}>
      <GoogleButton
        onClick={async () => {
          try {
            await signInWithGoogle();
            push(ROUTES.root);
          } catch (err) {
            /* empty */
          }
        }}
      />

      <Divider />

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {!!errors.afterSubmit && (
            <Alert severity="warning" variant="filled">
              {errors.afterSubmit.message}
            </Alert>
          )}

          <RHFTextField
            id="email"
            name="email"
            placeholder={translate('features.login.email.placeholder')}
            size="small"
            variant="outlined"
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {translate('features.login.email.cta')}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Stack>
  );
}
