import { forwardRef } from 'react';
import NextLink from 'next/link';
import { Box, Link, BoxProps } from '@mui/material';

import { ROUTES } from '@core/config';

export interface LogoProps extends BoxProps {
  width?: number;
  light?: boolean;
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, width, light, sx }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="img"
        src={light ? '/logo-white.png' : '/logo.png'}
        sx={{
          width: width || 64,
          height: width || 64,
          cursor: 'pointer',
          ...sx,
        }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={NextLink}
        href={ROUTES.root}
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

export default Logo;
